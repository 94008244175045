/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  var Toolbox = {
    calculate: function() {
      var four  = Number(document.getElementById('four_items_guests').value);
      var five  = Number(document.getElementById('five_items_guests').value);
      var six   = Number(document.getElementById('six_items_guests').value);
      var seven = Number(document.getElementById('seven_items_guests').value);
      var eight = Number(document.getElementById('eight_items_guests').value);
      var nine  = Number(document.getElementById('nine_items_guests').value);
      
      var bev_price = Number(document.getElementById('bev_price').value);
      var bev_num_guests = Number(document.getElementById('bev_num_guests').value);
      
      var station_price = Number(document.getElementById('station_price').value);
      var station_num_guests = Number(document.getElementById('station_num_guests').value);
      
      var rental_fees = Number(document.getElementById('rental_fees').value);
      
      var sales_tax = Number(document.getElementById('sales_tax').value);

      
      var total = 0;
      var service_fee = 0;
      
      total = (four * 10.50);
      total += (five * 13.10);
      total += (six * 15.72);
      total += (seven * 17.72);
      total += (eight * 19.22);
      total += (nine * 21.47); 
      
      total += (bev_price * bev_num_guests);
      
      total += (station_price * station_num_guests);
      
      total += rental_fees;
      
      service_fee = (total * 0.2);
        
      total += service_fee;
      
      if (sales_tax !== "" && sales_tax !== 0){
        total += (total * (sales_tax / 100) );
      } 


      document.getElementById('grand_total').innerText = parseFloat(total).toFixed(2);
      document.getElementById('grand_total').textContent = parseFloat(total).toFixed(2);
      
      document.getElementById('grand_total_hidden').value = parseFloat(total).toFixed(2);
      
      document.getElementById('service_fee').innerText = parseFloat(service_fee).toFixed(2);
      document.getElementById('service_fee').textContent = parseFloat(service_fee).toFixed(2);
    },
    calculate_venue: function() {
      var standard_venue_rental = Number(document.getElementById('standard_venue_rental').value);
      var additional_hours = Number(document.getElementById('additional_hours').value);
      var wr_combination = Number(document.getElementById('wr_combination').value);

      
      var total = 0;
      
      
      total += (standard_venue_rental + additional_hours + wr_combination);
      
      document.getElementById('venue_cost_total').textContent = parseFloat(total).toFixed(2);
      
      return total;
    },
    calculate_food: function() {
       var food_person = Number(document.getElementById('food_person').value);
        var food_num_guests = Number(document.getElementById('food_num_guests').value);
        
        var cost_per_person = food_person * food_num_guests;
        
        var service_fee = Number(document.getElementById('service_fee').value);
        
        var num_bartenders = Number(document.getElementById('num_bartenders').value);
        
        var bartender_fee = num_bartenders * 120;
        
        var barmixers_fee = Number(document.getElementById('barmixers').value);
        
        var cake_num_guests = Number(document.getElementById('cake_num_guests').value);

        var cake_fee = cake_num_guests * 0.85;
        
        var total = 0;
        
        total += (cost_per_person + service_fee + bartender_fee + barmixers_fee + cake_fee);
        
        document.getElementById('food_cost').textContent = parseFloat(cost_per_person).toFixed(2);
        document.getElementById('service_fee_total').textContent = parseFloat(service_fee).toFixed(2);
        document.getElementById('bartender_cost').textContent = parseFloat(bartender_fee).toFixed(2);
        document.getElementById('cake_cost').textContent = parseFloat(cake_fee).toFixed(2);
        
        document.getElementById('food_cost_total').textContent = parseFloat(total).toFixed(2);
        
        return total;
    }, 
    calculate_additional: function() {
      var rental_linens = Number(document.getElementById('rental_linens').value);
          
      var standard_linens = Number(document.getElementById('standard_linens').value);
      var standard_linens_fee = standard_linens * 5;
      
      var chair_covers = Number(document.getElementById('chair_covers').value);
      
      var arch = Number(document.getElementById('arch').value);
      
      var other_items = Number(document.getElementById('other_items').value);

      var total = 0;
      
      total += (rental_linens + standard_linens_fee + chair_covers + arch + other_items);
      
      document.getElementById('linens_total').textContent = parseFloat(standard_linens_fee).toFixed(2);
      document.getElementById('additional_cost_total').textContent = parseFloat(total).toFixed(2);
      
      return total;
    },
    calculate_reception_total: function() {
      var grandtotal = 0;
            
      var venue = calculate_venue();
      var food = calculate_food();
      var additional = calculate_additional();
      
      var pre_tax = venue + food + additional;
      
      var tax = Number(document.getElementById('sales_tax').value);
      
      grandtotal = (pre_tax + ((pre_tax / 100) * tax));
      
      
      document.getElementById('before_tax_total').textContent = parseFloat(pre_tax).toFixed(2);
      document.getElementById('grand_total').textContent = parseFloat(grandtotal).toFixed(2);
    }   
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).ready( function(){

          $('input[type="button"]').click(function(e) {
            if (this.id === 'calculate_hors') {
              Toolbox.calculate();
            }
            if (this.id === 'calculate_venue') {
              Toolbox.calculate_venue();
            }
            if (this.id === 'calculate_food') {
              Toolbox.calculate_food();
            }
            if (this.id === 'calculate_additional') {
              Toolbox.calculate_additional();
            }
            if (this.id === 'calculate_reception_total') {
              Toolbox.calculate_reception_total();
            }
          });

          if (! $(document.body).hasClass('home')) {
            $(function() {
              $('a[href*="#"]:not([href="#"])').click(function() {
                if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                  var target = $(this.hash);
                  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                  if (target.length) {
                    $('html, body').animate({
                      scrollTop: ($(target).offset().top) - 50
                    }, 1000);
                    return false;
                  }
                }
              });
            });
          }
          
          $('.map').click(function () {
            $('.map iframe').css("pointer-events", "auto");
          });

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
